import React, { Component } from 'react';

import { Grid, Typography, Icon, IconButton, Popover } from '@mui/material';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import Skeleton from '@mui/material/Skeleton';

import renderHTML from 'react-render-html';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

// import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Event } from '@mui/icons-material';

import Loader from 'components/Loader';

import Api from 'api/Api';
import NoData from 'components/blocks/NoData';

class EmployeeActivities extends Component { 
    constructor(props) {
        super();
        this.state = {

            no_data: false,
            activities: [],

            loading: false,

            calendar: false,
            selected_date: new Date(),

            day: '',
            month: '',
            date: '',

            popup_loader: false,

            current_activity: false,
            info_popup: false,

            student_course: false,
            emi_row: false,

            lead: false,
            comment: false,

            student: false
        }
    }

    componentDidMount = () => {

        if(this.props.account_token && (this.props.employee_id || this.props.student_id)){
        
            if(this.props.employee_id){
            
                this.loadActivities(this.props.account_token, this.props.employee_id, false);
            }

            if(this.props.student_id){

                this.loadActivities(this.props.account_token, false, this.props.student_id);
            }
        }
    }

    render () {
        
        return (

            <Grid container spacing={3}>

                <Grid item xs={12} sm={12}>
            
                    <Timeline className={"timeline activity-timeline " + (this.props.student_id ? 'history-view' : '')}>

                        {this.props.employee_id &&
                        
                            <TimelineItem className="timeline-header">
                                <TimelineOppositeContent>
                                    <Typography color="textSecondary" className="timeline-time">
                                        <b>{this.state.day}</b>
                                        <span>{this.state.month}</span>
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot>
                                        <span>{this.state.date}</span>
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div className="timeline-content">

                                        <IconButton size="medium" onClick={(e) => {

                                            this.setState({calendar: e.currentTarget})
                                        }}>

                                            <Event />
                                        </IconButton>

                                        <Popover
                                            onClose={() => {
                                                this.setState({calendar: false})
                                            }}
                                            open={this.state.calendar ? true : false}
                                            anchorEl={this.state.calendar}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <div className="popover-confirm" style={{width:360}}>
                        
                                                <div className="content" style={{width:340, padding:20}}>
                        
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        
                                                        <DateCalendar
                                                            disableToolbar
                                                            format="MM/dd/yyyy"
                                                            value={this.state.selected_date ? this.state.selected_date : null}
                                                            onChange={(e, date) => {
                                                                
                                                                this.setState({selected_date: e, calendar: false}, () => {

                                                                    this.loadActivities(this.props.account_token, this.props.employee_id, false);
                                                                });
                                                            }}
                                                            autoOk={true}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </Popover>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        }

                        {this.state.no_data &&
                        
                            <NoData size="small">Data not found!</NoData>
                        }

                        {this.state.loading &&
                        
                            <div>
                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} style={{borderRadius:10}} />
                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                            </div>
                        }

                        {this.state.activities && this.state.activities.length > 0
                            ?

                                this.state.activities.map((_activity) => {

                                    var classes = '';

                                    if(
                                        _activity.act_type == 'lead_created'
                                        ||
                                        _activity.act_type == 'course_emi_payment' || _activity.act_type == 'student_assign_course' || _activity.act_type == 'student_course_activated'
                                        ||
                                        _activity.act_type == 'lead_assigned_to'
                                        ||
                                        _activity.act_type == 'lead_status_update'
                                        ||
                                        _activity.act_type == 'lead_update'
                                        ||
                                        _activity.act_type == 'task_created'
                                        ||
                                        _activity.act_type == 'comment_add'
                                        ||
                                        _activity.act_type == 'student_created'
                                    ){

                                        classes += ' has-act';
                                    }

                                    return (

                                        <TimelineItem key={"activity_" + _activity.id} className={classes} onClick={(e) => {

                                            if(
                                                _activity.act_type == 'lead_created'
                                                ||
                                                _activity.act_type == 'course_emi_payment' || _activity.act_type == 'student_assign_course' || _activity.act_type == 'student_course_activated'
                                                ||
                                                _activity.act_type == 'lead_assigned_to'
                                                ||
                                                _activity.act_type == 'lead_status_update'
                                                ||
                                                _activity.act_type == 'lead_update'
                                                ||
                                                _activity.act_type == 'task_created'
                                                ||
                                                _activity.act_type == 'comment_add'
                                                ||
                                                _activity.act_type == 'student_created'
                                            ){

                                                this.setState({info_popup: e.currentTarget, current_activity: _activity});

                                                if(_activity.act_type == 'course_emi_payment' || _activity.act_type == 'student_assign_course' || _activity.act_type == 'student_course_activated'){

                                                    this.loadCourseData(_activity)
                                                }

                                                if(_activity.act_type == 'lead_status_update' || _activity.act_type == 'lead_update'){

                                                    this.loadLead(_activity)
                                                }

                                                if(_activity.act_type == 'comment_add'){

                                                    this.loadComment(_activity)
                                                }

                                                if(_activity.act_type == 'student_created'){

                                                    this.loadStudent(_activity)
                                                }
                                            }
                                        }}>
                                            <>
                                                {this.props.employee_id &&
                                                
                                                    <TimelineOppositeContent>
                                                        <Typography color="textSecondary" className="timeline-time">{_activity.time_formatted}</Typography>
                                                    </TimelineOppositeContent>
                                                }
                                                <TimelineSeparator>
                                                    <TimelineDot>
                                                        <Icon>{_activity.icon}</Icon>
                                                    </TimelineDot>

                                                    {this.props.employee_id &&
                                                        
                                                        <TimelineConnector />
                                                    }
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <div className="timeline-content">
                                                        {renderHTML(_activity.action)}
                                                    </div>
                                                </TimelineContent>
                                            </>
                                        </TimelineItem>
                                    )
                                })
                            :
                                null
                        }       
                    </Timeline>
                    
                    <Popover
                        open={this.state.info_popup ? true : false}
                        anchorEl={this.state.info_popup}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        onClose={() => {

                            this.setState({current_activity: false, info_popup: false})
                        }}
                    >
                        <div className="popover-confirm" style={{width:400}}>

                            {this.state.current_activity
                                ?
                                    <>
                                        <div className="header">
                                            <strong>{this.popupHeading(this.state.current_activity)}</strong>
                                        </div>
                                        <div className="content" style={{padding:20}}>

                                            {this.popupData(this.state.current_activity)}
                                        </div>

                                        {this.popupFooter(this.state.current_activity)}
                                    </>
                                :
                                    null
                            }

                            <Loader loading={this.state.popup_loader} />
                        </div>
                    </Popover>
                </Grid>
            </Grid>
        )
    }

    popupData = (_activity) => {

        if(_activity.act_type == 'course_emi_payment' || _activity.act_type == 'student_assign_course' || _activity.act_type == 'student_course_activated'){

            var student_course = this.state.student_course;
            var emi_row = this.state.emi_row;

            return (

                <div className="info-card-primary">

                    <div className="info-card-body">

                        {(student_course) &&

                            <>
                                <ul className="info-list">

                                    <li>
                                        <label>
                                            <b>Student Details</b>
                                        </label>
                                    </li>
                                    <li>
                                        <label>Student Name:</label>
                                        <span>{student_course.name}</span>
                                    </li>
                                    <li>
                                        <label>Phone:</label>
                                        <span>{student_course.contact_no}</span>
                                    </li>
                                    <li>
                                        <label>
                                            <b>Course Details</b>
                                        </label>
                                    </li>
                                    <li>
                                        <label>Course:</label>
                                        <span>{_activity.activity_data.course_title}</span>
                                    </li>

                                    {_activity.activity_data.hasOwnProperty('exam_centre') &&
                                    
                                        <li>
                                            <label>Exam Centre:</label>
                                            <span>{_activity.activity_data.exam_centre}</span>
                                        </li>
                                    }
                                    
                                    {_activity.activity_data.hasOwnProperty('batch') &&
                                    
                                        <li>
                                            <label>Batch:</label>
                                            <span>{_activity.activity_data.batch}</span>
                                        </li>
                                    }

                                    {emi_row &&
                                        <>
                                            <li><hr className="divider" /></li>
                                            <li>
                                                <label>Payment Date:</label>
                                                <span>{emi_row.emi_date_formatted}</span>
                                            </li>
                                            <li>
                                                <label>Paid on Date:</label>
                                                <span>{emi_row.paid_on_date_formatted}</span>
                                            </li>
                                            <li><hr className="divider" /></li>
                                            <li>
                                                <label>Amount to Pay:</label>
                                                <span>{emi_row.emi_amount}</span>
                                            </li>
                                            <li>
                                                <label>Amount Paid:</label>
                                                <span>{emi_row.amount_paid}</span>
                                            </li>
                                            <li>
                                                <label>Payment Status:</label>
                                                <span>{emi_row.installment_status_label}</span>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </>
                        }
                    </div>
                </div>
            )
        }

        if(_activity.act_type == 'task_created'){

            return (

                <div className="info-card-primary">

                    <div className="info-card-body">

                        <ul className="info-list">

                            <li>
                                <label>Task:</label>
                                <span>{_activity.activity_data.task}</span>
                            </li>
                            <li>
                                <label>Date of task:</label>
                                <span>{_activity.activity_data.task_date}</span>
                            </li>
                            <li>
                                <label>Time of task:</label>
                                <span>{_activity.activity_data.task_time}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            )
        }

        if(_activity.act_type == 'lead_created' || _activity.act_type == 'lead_assigned_to' || _activity.act_type == 'lead_status_update' || _activity.act_type == 'lead_update' || _activity.act_type == 'comment_add'){

            var lead = _activity.activity_data;

            if(this.state.lead){

                lead = this.state.lead;
            }

            return (

                <div className="info-card-primary">

                    <div className="info-card-body">

                        <ul className="info-list">

                            <li>
                                <label>Name:</label>
                                <span>{lead.first_name} {lead.last_name}</span>
                            </li>
                            <li>
                                <label>Phone:</label>
                                <span>{lead.mobile_number}</span>
                            </li>
                            <li>
                                <label>Email:</label>
                                <span>{lead.email}</span>
                            </li>
                            <li>
                                <label>Address:</label>
                                <span>{lead.address}</span>
                            </li>
                            <li>
                                <label>Lead Source:</label>
                                <span>{lead.lead_source}</span>
                            </li>

                            {_activity.act_type == 'comment_add'
                                ?
                                    <>
                                        <li><hr className="divider" /></li>
                                        <li>
                                            <label>
                                                <b>Comment</b>
                                            </label>
                                        </li>
                                        <li>
                                            <span style={{textAlign:'left'}}>{this.state.comment ? this.state.comment.comment : ''}</span>
                                        </li>
                                    </>
                                :
                                    null
                            }
                        </ul>
                    </div>
                </div>
            )
        }

        if(_activity.act_type == 'student_created'){

            return (

                <div className="info-card-primary">

                    <div className="info-card-body">

                        {this.state.student
                            ?
                                <ul className="info-list">

                                    <li>
                                        <label>Name:</label>
                                        <span>{this.state.student.name}</span>
                                    </li>
                                    <li>
                                        <label>Email:</label>
                                        <span>{this.state.student.email}</span>
                                    </li>
                                    <li>
                                        <label>Contact Number:</label>
                                        <span>{this.state.student.contact_no}</span>
                                    </li>
                                </ul>
                            :
                                null
                        }
                    </div>
                </div>
            )
        }

    }

    popupFooter = (_activity) => {

        if(_activity.act_type == 'lead_created' || _activity.act_type == 'lead_assigned_to' || _activity.act_type == 'lead_status_update' || _activity.act_type == 'lead_update'){

            return (

                <div className="popover-actions">

                    <a className="btn" target="_blank" href={"/leads/view/" + _activity.act_value}>View Lead</a>
                </div>
            )
        }
    }

    popupHeading = (_activity) => {

        if(_activity.act_type == 'task_created'){

            return 'Task details';
        }

        if(_activity.act_type == 'lead_created' || _activity.act_type == 'lead_assigned_to' || _activity.act_type == 'lead_status_update' || _activity.act_type == 'lead_update'){

            return "Lead details";
        }

        if(_activity.act_type == 'student_assign_course' || _activity.act_type == 'student_course_activated'){

            return "Course Details";
        }

        if(_activity.act_type == 'course_emi_payment'){

            return "Course EMI Payment";
        }

        if(_activity.act_type == 'comment_add'){

            return "Lead comment by " + _activity.first_name + ' ' + _activity.last_name;
        }

        if(_activity.act_type == 'student_created'){

            return "Student details";
        }
    }

    loadActivities = (account_token, employee_id, student_id) => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('date', format(this.state.selected_date, "yyyy-MM-dd"));

        if(employee_id){

            formData.append('id', employee_id);
        }

        if(student_id){

            formData.append('student_id', student_id);
        }

        this.setState({loading: true, no_data: false});

        var url = 'users/activities';

        if(student_id){

            var url = 'students/activities';
        }

        Api.post(url, formData, function(data){

            that.setState({loading: false});

            if(data.status){

                if(student_id){

                    if(data.activities.length == 0){

                        that.setState({no_data: true, activities: []});
                    }else{
    
                        that.setState({activities: data.activities, no_data: false});
                    }
                }else{
                    if(data.activities.length == 0){

                        that.setState({no_data: true, activities: [], day: data.day, month: data.month, date: data.date});
                    }else{

                        that.setState({activities: data.activities, no_data: false, day: data.day, month: data.month, date: data.date});
                    }
                }
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
    
    loadLead = (_activity) => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('lead_id', _activity.act_value);

        this.setState({popup_loader: true});

        Api.post('leads/single', formData, function(data){

            that.setState({popup_loader: false});

            if(data.status){

                that.setState({lead: data.lead});
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    loadCourseData = (_activity) => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('student_course_id', _activity.act_value);

        if(_activity.activity_data.hasOwnProperty('emi_row_id')){
        
            formData.append('emi_row_id', _activity.activity_data.emi_row_id);
        }

        this.setState({popup_loader: true});

        Api.post('students/student_course_details', formData, function(data){

            that.setState({popup_loader: false});

            if(data.status){
            
                that.setState({student_course: data.student_course, emi_row: data.emi_row});
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    loadComment = (_activity) => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('comment', _activity.act_value);

        this.setState({popup_loader: true});

        Api.post('leads/comments/single', formData, function(data){

            that.setState({popup_loader: false});

            if(data.status){

                var lead = {};
                lead['row_id'] = data.comment.row_id;
                lead['code'] = data.comment.code;
                lead['first_name'] = data.comment.first_name;
                lead['last_name'] = data.comment.last_name;
                lead['mobile_number'] = data.comment.mobile_number;
                lead['email'] = data.comment.email;
                lead['lead_source'] = data.comment.lead_source;

                that.setState({lead: lead, comment: data.comment});
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    loadStudent = (_activity) => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('student_id', _activity.act_value);
        
        formData.append('type', 'single');

        this.setState({popup_loader: true});

        Api.post('students/single', formData, function(data){

            that.setState({popup_loader: false});

            if(data.status){

                that.setState({student: data.student})
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default EmployeeActivities;
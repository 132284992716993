import React, { Component } from 'react';

import { Grid, Box, FormControl, InputLabel, Select, MenuItem, Popover, IconButton } from '@mui/material';

import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';

import Main from 'pages/sections/Main';
import Api from 'api/Api';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { CalendarToday, Close } from '@mui/icons-material';

import LeadTaskRow from 'pages/leads/blocks/LeadTaskRow';

import NoData from 'components/blocks/NoData';

import DateHelper from 'helpers/DateHelper';
import LayoutHelper from 'helpers/LayoutHelper';

import moment from 'moment';

class TasksList extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: '',

            error_message: '',
            success_message: '',

            loading_tasks: false,

            page: 1,
            total_pages: 0,

            total: 0,

            tasks: [],
            tasks_options: [],

            filter_task: '',
            filter_status: '',
            filter_sort: 'added_on__desc',

            range_popover: false,

            range_init: false,
            range_start: false,
            range_end: false,
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token && user){

            user = JSON.parse(user);

            this.setState({account_token: account_token, user: user});

            this.loadTasks(account_token, true);
        }else{

            window.location = Api.server_url + 'logout';
        }
    }

    render () {

        return (

            <Main page="tasks" active_link="tasks" title={<>Tasks <span className="fs-14 cg-5">({this.state.total})</span></>} error_message={this.state.error_message} success_message={this.state.success_message}>
                
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>

                        <div className="horizontal-tabs">
                            <div className="horizontal-tabs-content">

                                <div className="tab-content">
                            
                                    <Box component="div" whiteSpace="normal">

                                        <div className="box-header">

                                            <div className="filters">

                                                <strong className="fs-14 cg-6 mr-10">Filters:</strong>

                                                <FormControl variant="standard" style={{ minWidth: 120 }}>
                                                    <InputLabel>Task Type</InputLabel>
                                                    <Select
                                                        value={this.state.filter_task}
                                                        onChange={(event) => {

                                                            this.setState({filter_task: event.target.value, page: 1}, () => {

                                                                this.loadTasks(this.state.account_token, true);
                                                            })
                                                        }}
                                                        label="Type"
                                                    >
                                                        <MenuItem value=""><em>All</em></MenuItem>

                                                        {this.state.tasks_options.map((option) => {

                                                            return <MenuItem key={'t_'+option.key} value={option.key}>{option.task}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>

                                                <FormControl variant="standard" style={{ minWidth: 120, marginLeft:10 }}>
                                                    <InputLabel>Status</InputLabel>
                                                    <Select
                                                        value={this.state.filter_status}
                                                        onChange={(event) => {

                                                            this.setState({filter_status: event.target.value, page: 1}, () => {

                                                                this.loadTasks(this.state.account_token, true);
                                                            })
                                                        }}
                                                        label="Status"
                                                    >
                                                        <MenuItem value=""><em>All</em></MenuItem>
                                                        <MenuItem value="new">New</MenuItem>
                                                        <MenuItem value="done">Done</MenuItem>
                                                        <MenuItem value="not_done">Not Done</MenuItem>
                                                    </Select>
                                                </FormControl>

                                                <FormControl variant="standard" style={{ minWidth: 120, marginLeft:10, fontSize: 12 }}>
                                                    <InputLabel>Sort By</InputLabel>
                                                    <Select
                                                        value={this.state.filter_sort}
                                                        onChange={(event) => {

                                                            this.setState({filter_sort: event.target.value, page: 1}, () => {

                                                                this.loadTasks(this.state.account_token, true);
                                                            })
                                                        }}
                                                        label="Sort By"
                                                    >
                                                        <MenuItem value="added_on__desc">Creation Date - Desc</MenuItem>
                                                        <MenuItem value="added_on__asc">Creation Date - Asc</MenuItem>
                                                        <MenuItem value="task_date__desc">Task Date - Desc</MenuItem>
                                                        <MenuItem value="task_date__asc">Task Date - Asc</MenuItem>
                                                    </Select>
                                                </FormControl>


                                                <IconButton style={{marginTop:12, marginLeft:10}} onClick={(event) => {
                                                    
                                                    this.setState({range_popover: event.currentTarget, range_init: true})
                                                }}>
                                                    {this.state.range_start != false
                                                        ?           
                                                            <div className="flex-center vertical" style={{width:75}}>
                                                                <span className="fs-11" style={{lineHeight:1.1}}>{DateHelper.formattedDate(new Date(this.state.range_start))}</span>

                                                                {this.state.range_end != false &&
                                
                                                                    <span className="fs-11" style={{lineHeight:1.1}}>{DateHelper.formattedDate(new Date(this.state.range_end))}</span>
                                                                }
                                                                
                                                            </div>
                                                        :
                                                            <CalendarToday className="fs-20" />
                                                    }
                                                </IconButton>
                                                <Popover
                                                    id="filter_date"
                                                    onClose={() => {
                                                        
                                                        // this.setState({range_popover: false})
                                                    }}
                                                    open={this.state.range_popover ? true : false}
                                                    anchorEl={this.state.range_popover}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <div className="popover-confirm" style={{width:360}}>

                                                        <div className='header'>
                                                            <strong>Choose Date Range:</strong>
                                                        </div>

                                                        <div className="content" style={{width:340, padding:20}}>

                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                        
                                                                <DateCalendar
                                                                    disableToolbar
                                                                    variant="static"
                                                                    inputFormat="MM/dd/yyyy"
                                                                    inputVariant="standard"
                                                                    // minDate={this.minDate()}
                                                                    value={this.state.range_start != false ? this.state.range_start : null}
                                                                    onChange={(e, date) => {

                                                                        if(this.state.range_init){
                                
                                                                            this.setState({range_start: moment(e).format("YYYY-MM-DD"), range_init: false})
                                                                        }else{
                                
                                                                            this.setState({range_end: moment(e).format("YYYY-MM-DD"), range_popover: false, page: 1}, () => {
                                
                                                                                this.loadTasks(this.state.account_token, true);
                                                                            });                                            
                                                                        }
                                                                    }}
                                                                    autoOk={true}
                                                                    KeyboardButtonProps={{
                                                                        'aria-label': 'change date',
                                                                    }}
                                                                />
                                                            </LocalizationProvider>

                                                            {!this.state.range_init &&

                                                                <div className='flex-center'>
                                                                    <strong className='c-r'>Choose second date</strong>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Popover>

                                                {(this.state.filter_task || this.state.filter_status || this.state.range_start || this.state.range_end) &&

                                                    <IconButton style={{marginTop:12, marginLeft:10}} onClick={(event) => {
                                                    
                                                        this.setState({filter_task: '', filter_status: '', range_start:false, range_end:false}, () =>{

                                                            this.loadTasks(this.state.account_token, true);
                                                        });
                                                    }} title="Clear Filters">

                                                        <Close className="fs-16 c-r" />
                                                    </IconButton>
                                                }
                                            </div>

                                            {this.state.total_pages > 0 &&
                                            
                                                <Pagination count={this.state.total_pages} page={this.state.page} onChange={(event, value) => {
                                                        
                                                    this.setState({page: value}, () => {
                                                        this.loadTasks(this.state.account_token, true);
                                                    });
                                                }} />
                                            }
                                        </div>

                                        {this.state.loading_tasks &&
                
                                            <div>
                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} style={{borderRadius:10}} />
                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                                <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                                            </div>
                                        }

                                        {((this.state.tasks.length > 0) && (!this.state.loading_tasks))
                                            ?
                                                <div className="items-list">

                                                    {this.state.tasks.map((_task) => {

                                                        return <LeadTaskRow
                                                                    task={_task}
                                                                    page="tasks"
                                                                    key={"lead_task_" + _task.row_id}
                                                                    account_token={this.state.account_token}
                                                                    onSuccess={(message) => {

                                                                        LayoutHelper.addSuccessMessage(this, message)
                                                                    }}
                                                                    onError={(message) => {

                                                                        LayoutHelper.addErrorMessage(this, message)
                                                                    }}
                                                                    updateTaskStatus={(row_id, status) => {

                                                                        var _tasks = this.state.tasks;

                                                                        if(_tasks && _tasks.length > 0){

                                                                            for(var t in _tasks){
                                                                
                                                                                var task = _tasks[t];
                                                                
                                                                                if(task.row_id == row_id){
                                                                
                                                                                    _tasks[t]['status'] = status;
                                                                                }
                                                                            }
                                                                
                                                                            this.setState({'total': 0});

                                                                            window.setTimeout(() => {

                                                                                this.setState({'tasks': _tasks});
                                                                                this.setState({'total': _tasks.length});
                                                                            }, 200);
                                                                        }
                                                                    }}
                                                                />
                                                    })}
                                                </div>
                                            :
                                                null
                                        }


                                        {(!this.state.loading_tasks && this.state.total <= 0) &&
                                            <NoData message="No tasks available" size="medium"></NoData>
                                        }
                                        
                                        <Grid container spacing={0} direction="column" alignItems="flex-end" justifyContent="center" style={{marginTop:'20px'}}>
                                            <Grid item xs={6}>

                                                {this.state.total_pages > 0 &&

                                                    <Pagination count={this.state.total_pages} page={this.state.page} onChange={(event, value) => {
                                                        
                                                        this.setState({page: value}, () => {
                                                            this.loadTasks(this.state.account_token, true);
                                                        });
                                                    }} />
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Main>
        )
    }

    loadTasks = (account_token, loader) => {
    
        var formData = new FormData();

        formData.append('account_token', account_token);
        formData.append('page', this.state.page);

        formData.append('filter_sort', this.state.filter_sort);

        if(this.state.filter_task != ''){
            formData.append('task', this.state.filter_task);
        }

        if(this.state.filter_status != ''){
            formData.append('status', this.state.filter_status);
        }

        if(this.state.range_start != ''){

            formData.append('start_date', moment(this.state.range_start).format('YYYY-MM-DD'));
        }

        if(this.state.range_end != ''){

            formData.append('end_date', moment(this.state.range_end).format('YYYY-MM-DD'));
        }

        if(loader){

            this.setState({loading_tasks: true});
        }

        var that = this;

        Api.post('tasks/all', formData, function(data){

            that.setState({loading_tasks: false});
            
            if(data.status){
            
                that.setState({
                    tasks: data.tasks,
                    total: data.total,
                    total_pages: data.total_pages,
                    tasks_options: data.tasks_options,
                });
            }else{

                if(data.code && data.code == 'no_task'){

                    that.setState({tasks:[], total: 0, total_pages: 0});
                }

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default TasksList;
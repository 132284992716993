import React, { useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { Add } from '@mui/icons-material';

import Api from 'api/Api';

import NoData from 'components/blocks/NoData';

import LeadTaskRow from './LeadTaskRow';
import Loader from 'components/Loader';

import LeadTaskForm from '../sections/LeadTaskForm';

function LeadTasks (props){ 

    const [reminder, setReminder] = useState(true);
    const [loading, setLoading] = useState(false);

    const [task_form, showTaskForm] = useState(false);

    const [remind_by, setRemindBy] = useState([]);

    const [loaded, setLoaded] = useState(false);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [per_page, setPerPage] = useState(0);
    const [tasks, setTasks] = useState([]);

    const [load_more, setLoadMore] = useState(false);
    const [loading_more, setLoadingMore] = useState(false);
    const [all_loaded, setAllLoaded] = useState(false);

    const [loading_tasks, setLoadingTasks] = useState(false);

    var lead = props.lead;

    useEffect(() => {
        
        if(!loaded){
        
            if(lead){
            
                setLoaded(true)
                loadTasks(lead, true, false, 0);
            }
        }
    });

    if(lead){

        return (

            <Box component="div" whiteSpace="normal">

                {!task_form &&
                    <div className="box-header">
                    
                        <h2>Tasks <span>({total})</span></h2>

                        <Button startIcon={<Add />} color="secondary" size="small" onClick={() => {

                            showTaskForm(true)
                        }}>Add Task</Button>
                    </div>
                }

                {task_form &&
                
                    <LeadTaskForm
                        tasks_options={props.tasks_options}
                        lead={props.lead}
                        account_token={props.account_token}
                        showTaskForm={() => {

                            showTaskForm(false);

                            loadTasks(props.lead, false, false, 0);
                        }}
                        hide_form={() => {

                            showTaskForm(false);
                        }}
                        success_message={(message) => {

                            props.success_message(message);
                        }}
                        error_message={(message) => {

                            props.error_message(message);
                        }}
                    />
                }
                
                {loading_tasks &&
                
                    <div>
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={50} style={{borderRadius:10}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                        <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                    </div>
                }

                {(tasks && tasks.length > 0) && (!task_form)
                    ?

                        <div className="items-list">

                            {tasks.map((_task) => {

                                return <LeadTaskRow
                                            task={_task}
                                            key={"lead_task_" + _task.row_id}
                                            account_token={props.account_token}
                                            onSuccess={(message) => {
                                                props.success_message(message)
                                            }}
                                            onError={(message) => {
                                                props.error_message(message)
                                            }}
                                            updateTaskStatus={(row_id, status) => {

                                                var _tasks = tasks;

                                                if(_tasks && _tasks.length > 0){

                                                    for(var t in _tasks){
                                        
                                                        var task = _tasks[t];
                                        
                                                        if(task.row_id == row_id){
                                        
                                                            _tasks[t]['status'] = status;
                                                        }
                                                    }
                                        
                                                    setTotal(0);

                                                    window.setTimeout(() => {

                                                        setTasks(_tasks);
                                                        setTotal(_tasks.length);
                                                    }, 200);
                                                }
                                            }}
                                        />
                            })}

                            <div style={{minHeight: 100, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                                <Loader loading={loading_more} />

                                {load_more &&
                                
                                    <Button onClick={() => {

                                        loadTasks(lead, false, true, page)
                                    }}>
                                        Load More
                                    </Button>
                                }

                                {all_loaded &&
                                
                                    <span className='op-7'>No more tasks to load.</span>
                                }
                            </div>
                        </div>
                    :
                        null
                }

                {(!task_form && !loading_tasks && tasks.length <= 0) &&
                    <NoData message="No task created for this lead" size="small">
                        <Button size="small" color="primary" variant="contained" onClick={() => {

                            showTaskForm(true)
                        }}>Add new task</Button>
                    </NoData>
                }
            </Box>
        )
    }else{

        return null
    }

    function loadTasks(lead, loader, loading_more, _page){
    
        var formData = new FormData();

        formData.append('account_token', props.account_token);
        formData.append('lead_id', lead.row_id);
        formData.append('page', _page);

        if(loader){

            setLoadingTasks(true);
        }

        Api.post('leads/tasks/load', formData, function(data){

            setLoadingTasks(false);

            if(data.status){

                if(_page == '0'){
            
                    setTasks(data.records);
                }else{

                    setTasks([...tasks, ...data.records]);
                }

                setTotal(data.total);
                setPage(data.page);
                setPerPage(data.per_page);

                if(data.page > 0 && (data.total > (data.per_page * data.page))){

                    setLoadMore(true)
                }else{

                    setLoadMore(false)
                    setAllLoaded(true)
                }

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
}

export default LeadTasks;